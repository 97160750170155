.logo-full-screen{
    bottom: 0;
    right: 2px;

    @media (min-width:0px) and (max-width:640px) {
    display: none;
    }
}

.logo-small-screen{
    
    display: none;
    @media (min-width:0px) and (max-width:640px) {
        top: 0;
        left: 0;
        display: block;
    }
}