@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

@font-face {
    font-family: 'Monaco';
    src: url('../../Assets/Monaco.ttf') format('truetype');
}

@font-face {
    font-family: 'Consolas';
    src: url('../../Assets/Consolas.ttf') format('truetype');
}

.text-area {
    height: 62vh;
    font-family: 'Monaco';
    font-style: normal;
    font-weight: 400;
    font-size: 21.0107px;
    color: #FFFFFF;

    &-twitter {
        color: #0DC43F;
        cursor: pointer;
    }

    @media (min-width:0px) and (max-width:640px) {
        height: 300px;
        font-size: 13.0107px;
    }
}

.text-area-login {
    height: 320px;
    
    font-family: 'Monaco';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 24px;

    @media (min-width:0px) and (max-width:640px) {
        height: 200px;
        font-size: 19.1163px;
        line-height: 15px;
        // margin: 50px 0;
    }
}

.download {
    font-family: 'Monaco';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;

    @media (min-width:0px) and (max-width:640px) {
        font-size: 20px;
    }
}

